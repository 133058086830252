import React from "react";
import BannerAnim from "rc-banner-anim";

import "rc-banner-anim/assets/index.css";
import "./About.scss";

import Photo from "../assets/banner/photo1.png";
import Curtain from "../assets/banner/curtain.png";

const { Element } = BannerAnim;

export const Banner = () => (
  <BannerAnim
    prefixCls="banner-user"
    type="across"
    dragPlay={false}
    autoPlay={true}
    autoPlaySpeed={4500}
  >
    <Element prefixCls="banner-user-elem" key={0}>
      <img className="workPhoto" src={Curtain} alt="Work" />
    </Element>

    <Element prefixCls="banner-user-elem" className="banner" key={1}>
      <img className="workPhoto" src={Photo} alt="Work" />
    </Element>
  </BannerAnim>
);
