import React from 'react';

import './About.scss'

import { Banner } from './Banner'

const About = (props) => (
  <div id='about' ref={props.Ref} >
    <h1 className='heading'>
      O firmie
    </h1>
    <h2 className='subHeader'>
      Krawcowa z doświadczeniem
    </h2>
    <div className='dataDiv'>
      Nazywam się Małgorzata Topa i zapraszam do mojej nowo otwartej pracowni krawieckiej "Gosia".
      Posiadam 20-letnie doświadczenie w zawodowym szyciu oraz wiele nowoczesnych maszyn takich jak renderka, podszywarka, overlock zwykły i mereszkowy dzięki czemu moje usługi są zawsze profesjonalne.
    </div>
    <br/>
    <h2 className='subHeader'>
      Usługi krawieckie Dobczyce
    </h2>
    <div className='dataDiv'>
      Wykonuje wszelkiego rodzaju profesjonalne usługi krawieckie.
      Klient może wybrać spośród moich własnych materiałów, lamówek, dodatków, lub dostarczyć swoje. <br/> <br/> Zapraszam do kontaktu telefonicznego lub mailowego.
    </div>
    <Banner/>
  </div>
)


export default About;
