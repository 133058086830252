import React from 'react';
import './fb.scss' // arkusz stylów

// obrazek fb
import fbImage from '../assets/fb.png'

var Fb = () => {
    return (
        < div className="slideout-widget widget-facebook" >
            <div className="slideout-widget-handler">
                <i className="icon-facebook">
                    <img className='fbimage' src={fbImage} alt='fbimage' />
                </i>
            </div>
            <div className="slideout-widget-content">
            <div className="fb-page" data-href="https://www.facebook.com/Pracownia-krawiecka-Gosia-772372593121601/" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Pracownia-krawiecka-Gosia-772372593121601/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Pracownia-krawiecka-Gosia-772372593121601/">Pracownia krawiecka Gosia</a></blockquote></div>            </div>
        </div >
    )
}
export default Fb;