import React, { Component } from "react";
import Service from "./Service"; // komponent z usługą
import "./Offer.scss"; // arkusz stylów

// iconki usług
import Manekin from "../assets/manekin.png"; // iconka manekinu
import window from "../assets/window.png"; // iconka manekinu
import Alba from "../assets/alba.png"; // iconka alby

export default class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offer: [
        {
          name: "Szycie firan i zasłon",
          img: window,
          offers: [
            "obszycie zwykłe: 1.50zł / mb",
            "obszycie lamówką: 1.50zł / mb(+ koszt lamówki - 1zł)",
            "wszyscie taśmy: 3zł / mb(+ koszt taśmy - 1.50zł)",
            "skracanie firan, zasłon: 4zł / mb",
          ],
        },

        {
          name: "Przeróbki krawieckie",
          img: Manekin,
          offers: [
            "skrócenie spodni: 7zł",
            "skrócenie spodni na podszywarce: 10zł",
            "skrócenie spodni garniturowych z tasiemką: 15zł",
            "wszycie zamka do spodni/ spódnicy: 10zł(+ koszt zamka)",
            "wszycie zamka do sukienki: 14zł(+ koszt zamka)",
            "wszycie zamka do kurtki: 20-35zł(+ koszt zamka)",
            "przeróbki sukien ślubnych",
          ],
        },

        {
          name: "Alby komunijne",
          img: Alba,
          offers: [
            "Szycie alb komunijnych: 250 - 300zł",
            "Przeróbki alb komunijnych",
          ],
        },
      ],
    };
  }

  render() {
    var offer = this.state.offer.map((i, index) => {
      // dynamiczne tworzenie divow z ofertami
      return (
        <Service key={index} name={i.name} img={i.img} offers={i.offers} />
      );
    });
    return (
      <div id="offer" ref={this.props.Ref}>
        <div className="heading">Oferta</div>

        <div className="dataDiv">
          Przykładowe usługi dostępne w naszej firmie :
        </div>

        <div id="offerList">{offer}</div>

        <div className="dataDiv">
          Reszta usług jest wyceniana na miejscu na podstawie ich rodzaju i
          czasu pracy.
        </div>
      </div>
    );
  }
}
