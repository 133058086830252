// ----- mapa dojazdu do firmy ----

import React, { Component } from 'react';
import { Map, Marker, TileLayer, Popup } from 'react-leaflet' // mapy
import 'leaflet/dist/leaflet.css' // style mapy
import L from 'leaflet'

export default class MapComponent extends Component {
    render() {

        var greenIcon = new L.icon({ // icona markera
            iconUrl: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Azure.png',
            iconSize: [80, 80],
            iconAnchor: [40, 80], // point of the icon which will correspond to marker's location
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        })

        return (
            <Map
                style={{ width: '99,5%', height: '100%', border: '1px solid #74479b', margin: '0 auto'}}
                center={this.props.position}
                zoom={this.props.zoom}
            >
                <TileLayer
                    attribution='&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <Marker position={this.props.position} icon={greenIcon}>
                    <Popup>Pracownia Krawiecka "Gosia"</Popup>
                </Marker>
            </Map>
        );
    }
}
