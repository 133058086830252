import React, { Component } from 'react';

export default class componentName extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        var offers= this.props.offers.map((i, index)=>{
            return <li className='offerItem' key={index}> {i} </li>
        })
        return (
            <div className='service'>
                <h2 className='subHeader'>
                    {this.props.name}
                </h2>
                <img className='serviceImage' src={this.props.img} alt='serviceImage' />

                <ul className='offers'>
                    
                    {offers}
                </ul>
            </div>
        );
    }
}
