import React from 'react'; // nawigacja
import "./Menu.scss"

const Menu = (props) => 
    <div className='menuDiv'>
      {
        props.names.map((elem, index)=>
          <div className='listElement' key={index} onClick={()=>{props.click(elem)}} >{elem}</div>
        )
      }

    </div>

export default Menu;